import React, { useEffect } from "react";

import "cordova-plugin-purchase";
import "@ionic/react/css/core.css";

import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "./App.css";
import { createTheme, StyledEngineProvider } from "@mui/material";

import { getStore, history } from "./state/Store";
import { Provider } from "react-redux";

import shared from "./styles/shared.module.css";
import PrivateRoute from "./components/PrivateRoute";
import CookiePopup from "./popups/cookiepopup/CookiePopup";

import eventEmitter from "./data/EventEmitter";

import PopupRenderer from "./popups/PopupRenderer";

import PublicAreaList from "./routes/publicarealist/PublicAreaList";
import TestWrapper from "./routes/testcard/TestCard";
import SignIn from "./routes/signin/SignIn";
import {
  IonTabs,
  IonRouterOutlet,
  IonApp,
  setupIonicReact,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonTabBar,
  IonContent,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Terms from "./routes/terms/Terms";
import UserCities from "./routes/usercities/UserCities";
import Route from "./components/RouteWrapper";
import { Redirect, useHistory, Route as RRRoute } from "react-router-dom";
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import { isAndroid, isApp, isIos, isMobile } from "./platform/PlatformManager";
import { globe, home, map, settings } from "ionicons/icons";
import Dashboard from "./routes/dashboard/Dashboard";
import Cities from "./routes/cities/Cities";
import CityDetails from "./routes/citydetails/CityDetails";
import Neighbourhoods from "./routes/neighbourhoods/Neighbourhoods";
import ActivityDetails from "./routes/activitydetails/ActivityDetails";
import NeighbourhoodDetails from "./routes/neighbourhooddetails/NeighbourhoodDetails";
import Settings from "./routes/settings/Settings";
import Achievements from "./routes/achievements/Achievements";
import AchievementDetails from "./routes/achievementdetails/AchievementDetails";
import LandmarkDetails from "./routes/landmarkdetails/LandmarkDetails";
import MapDraw from "./routes/mapdraw/MapDraw";
import CustomAreas from "./routes/customareas/CustomAreas";
import AccountSetup from "./routes/acountsetup/AccountSetup";
import AccountSetupImport from "./routes/accountsetupimport/AccountSetupImport";
import Register from "./routes/Register";
import Auth from "./routes/auth/Auth";
import PrivacyPolicy from "./routes/privacypolicy/PrivacyPolicy";
import AboutUs from "./routes/aboutus/AboutUs";
import VerifyEmail from "./routes/verify/VerifyEmail";
import Landing from "./routes/landing/Landing";
import Premium from "./routes/premium/Premium";
import Landmarks from "./routes/landmarks/Landmarks";
import Leaderboard from "./routes/leaderboard/Leaderboard";
import PremiumIAP from "./routes/premium/PremiumIAP";
import ReportInaccessibleAreaTool from "./routes/report-inaccesible-area/ReportInaccessibleAreaTool";
import { isLoggedInSelector } from "./state/userslice/UserSlice";
import GroupsList from "./routes/groupslist/GroupsList";
import GroupsCreate from "./routes/groups-create/GroupsCreate";
import GroupDetails from "./routes/group-details/GroupDetails";
import { useUserQuery } from "./state/api/ApiSlice";
import styles from "./App.module.css";
import classNames from "classnames";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import InAppPayments from "./data/iap/InAppPayments";
import JoinGroup from "./routes/group-join/JoinGroup";
import Support from "./routes/support/Support";
import PremiumIAPRevCat from "./routes/premium/PremiumIAPRevCat";
import LiveMap from "./routes/live-map/LiveMap";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import StravaPermissionsUpdate from "./routes/strava-permissions-update/StravaPermissionsUpdate";
import TrailRoadMap from "./routes/trail-road-map/TrailRoadMap";

/**
 * The event for this function is some Ionic custom scroll event and not a standard scroll event
 * @param e
 */
export const isAtBottom = (e) => {
  // Within three pixels of bottom (fixes issue on mobile where pixel values can be floats)
  const bottom =
    Math.abs(
      e.target.scrollEl.scrollHeight -
        e.target.detail.scrollTop -
        e.target.clientHeight
    ) < 3;

  if (bottom) {
    eventEmitter.emit("scroll_bottom");
  }
};
export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#d9814a",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat",
    fontSize: 14,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: "#fff",
        },
        root: {
          fontWeight: 700,
          borderRadius: 4,
          minHeight: 40,
        },
      },
    },
  },
});

// Used to handle Deep links / App links - MOBILE APP ONLY
const AppUrlListener: React.FC<any> = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".io").pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

/**
 * Component that calls the setup of in app payments
 * @constructor
 */
function IAPSetup() {
  const isLoggedIn = isLoggedInSelector(getStore().getState());
  const { data: userResponse } = useUserQuery({}, { skip: !isLoggedIn });
  const userId = userResponse?.success && userResponse.user?.user_id;

  // android needs a userid to setup in app payments
  useEffect(() => {
    // In app payments setup (Revcat and iOS)
    if (userId && isAndroid() && isApp()) {
      InAppPayments.setupInAppPayments(userId);
    }
  }, [userId]);

  // ios setup can happen on mount
  useEffect(() => {
    // In app payments setup iOS)
    if (isIos() && isApp()) {
      InAppPayments.setupInAppPayments();
    }
  }, []);
  return null;
}

setupIonicReact({
  animated: isMobile(),
});

function App() {
  const isLoggedIn = isLoggedInSelector(getStore().getState());
  useEffect(() => {
    if (isApp()) {
      CapacitorUpdater.notifyAppReady();
    }
  }, []);

  return (
    <Provider store={getStore()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <IonApp>
            <IonReactRouter history={history}>
              <AppUrlListener />
              <IAPSetup />
              <div className={shared.app} onScroll={isAtBottom}>
                <CookiePopup />
                <PopupRenderer />

                <IonRouterOutlet>
                  <RRRoute path="/tabs" render={() => <Tabs />} />
                  <Route path={"/"} exact={true}>
                    {/* Dont show landing page for app! That content is shown in app stores */}
                    {isLoggedIn ? (
                      <Redirect from={"/"} exact to={"/tabs/home"} />
                    ) : isApp() ? (
                      <Auth />
                    ) : (
                      <Landing />
                    )}
                  </Route>
                  <Route path={"/support"}>
                    <Support />
                  </Route>
                  <PrivateRoute path={"/join/:referral_code"}>
                    <JoinGroup />
                  </PrivateRoute>
                  <Route path={"/account-setup/:vendor"}>
                    <AccountSetup />
                  </Route>
                  <Route path={"/account_setup_import"}>
                    <AccountSetupImport />
                  </Route>
                  <Route path={"/register"}>
                    <Register />
                  </Route>
                  <Route path={"/auth"}>
                    <Auth />
                  </Route>
                  <Route path={"/signin"}>
                    <SignIn />
                  </Route>
                  <Route path={"/privacy_policy"}>
                    <PrivacyPolicy />
                  </Route>
                  <Route path={"/t_and_c"}>
                    <Terms />
                  </Route>
                  <Route path={"/about_us"}>
                    <AboutUs />
                  </Route>
                  <Route path={"/verify-email"}>
                    <VerifyEmail />
                  </Route>
                  <Route path={"/area-list"}>
                    <PublicAreaList />
                  </Route>
                  <Route path={"/testcard"}>
                    <TestWrapper />
                  </Route>
                  {/* Legacy url support - for users that are potentially using links from old emails*/}
                  <Route exact path={"/activity/:id"}>
                    <ActivityDetails />
                  </Route>
                </IonRouterOutlet>
              </div>
            </IonReactRouter>
          </IonApp>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}
const HomeTabRoutes = () => {
  return [
    <PrivateRoute exact path={"/tabs/:tab(home)/neighbourhoods/:uc_id"}>
      <Neighbourhoods />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/landmarks/:area_id"}>
      <Landmarks />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/map/:way_type/:area_id"}>
      <TrailRoadMap />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/neighbourhood-details/:id"}>
      <NeighbourhoodDetails />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/activity/:id"}>
      <ActivityDetails />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/achievements/:area_id"}>
      <Achievements />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/achievement/:id"}>
      <AchievementDetails />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/landmark/:id"}>
      <LandmarkDetails />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/leaderboard/:type/:period/:id"}>
      <Leaderboard />
    </PrivateRoute>,
    <PrivateRoute path={"/tabs/:tab(home)/report-map/:area_id"}>
      <ReportInaccessibleAreaTool />
    </PrivateRoute>,
    <PrivateRoute path={"/tabs/:tab(home)/groups/list"}>
      <GroupsList></GroupsList>
    </PrivateRoute>,
    <PrivateRoute path={"/tabs/:tab(home)/groups/create"}>
      <GroupsCreate></GroupsCreate>
    </PrivateRoute>,
    <PrivateRoute path={"/tabs/:tab(home)/group/:id"}>
      <GroupDetails></GroupDetails>
    </PrivateRoute>,
    <PrivateRoute path={"/tabs/:tab(home)/strava-permission-update"}>
      <StravaPermissionsUpdate />
    </PrivateRoute>,
    <PrivateRoute exact path={"/tabs/:tab(home)/premium/upgrade"}>
      {isApp() ? (
        isAndroid() ? (
          <PremiumIAPRevCat />
        ) : (
          <PremiumIAP />
        )
      ) : (
        <Premium />
      )}
    </PrivateRoute>,
  ];
};

const Tabs = () => {
  const { t } = useTranslation();
  return (
    <IonContent>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/tabs/:tab(home)">
            <Dashboard />
          </Route>
          <PrivateRoute exact path={"/tabs/:tab(home)/:id?"}>
            <Dashboard />
          </PrivateRoute>
          {/*Temporary route addition to handle incorrect link in email template*/}
          <PrivateRoute exact path={"/tabs/:tab(home)/:id?&:eid?"}>
            <Dashboard />
          </PrivateRoute>
          {HomeTabRoutes()}

          <Route exact path="/tabs/:tab(my-areas)">
            <UserCities />
          </Route>
          <PrivateRoute exact path={"/tabs/:tab(my-areas)/edit-areas"}>
            <CustomAreas />
          </PrivateRoute>

          <PrivateRoute exact path={"/tabs/:tab(my-areas)/draw-area"}>
            <MapDraw />
          </PrivateRoute>
          <Route exact path="/tabs/:tab(explore)">
            <Cities />
          </Route>
          <Route exact path={"/tabs/:tab(explore)/city/:id"}>
            <CityDetails />
          </Route>
          <Route exact path="/tabs/:tab(settings)">
            <Settings />
          </Route>
          {/*<Route exact path="/tabs/:tab(live-map)">*/}
          {/*  <LiveMap />*/}
          {/*</Route>*/}
        </IonRouterOutlet>
        {/* Hide tabs bar on desktop*/}
        <IonTabBar
          slot="bottom"
          className={classNames({ [styles.hidden]: !isMobile() })}
        >
          <IonTabButton tab="dashboard" href={"/tabs/home"}>
            <IonIcon icon={home} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>

          <IonTabButton tab="my_cities" href={"/tabs/my-areas"}>
            <IonIcon icon={map} />
            <IonLabel> Cities</IonLabel>
          </IonTabButton>

          {/*<IonTabButton tab="live-map" href={"/tabs/live-map"}>*/}
          {/*  <IonIcon icon={map} />*/}
          {/*  <IonLabel> Live Map</IonLabel>*/}
          {/*</IonTabButton>*/}

          <IonTabButton tab="map" href={"/tabs/explore"}>
            <IonIcon icon={globe} />
            <IonLabel>Explore</IonLabel>
          </IonTabButton>
          <IonTabButton tab="settings" href={"/tabs/settings"}>
            <IonIcon icon={settings} />
            <IonLabel>Settings</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonContent>
  );
};

export default App;
