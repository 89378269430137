import React from "react";
import Stat from "../../../../components/stat/Stat";
import styles from "./DashboardPercentages.module.css";
import { UserCityType } from "cp-types";
import LangKeys from "../../../../data/languages/LangKeys";
import SmallHeading from "../../../../components/headings/SmallHeading";
import { getPercent } from "../../../../data/utils/PercentageUtils";
import AccountTierFunctions from "../../../../data/account/AccountTierFunctions";
import { Link } from "react-router-dom";

export default function DashboardPercentages({
  userArea,
}: {
  userArea: UserCityType;
}) {
  const isFree = AccountTierFunctions.accountIsFree();
  return (
    <div>
      <SmallHeading
        left={`You've explored`}
        rightElement={
          <div className={styles.newForSubs}>New for Subscribers</div>
        }
      />
      <div className={styles.container}>
        <Stat
          value={`${getPercent(userArea.percent_covered)}%`}
          label={"Total"}
          valueColor={"#d9814a"}
        />
        <Link
          to={
            !isFree
              ? `/tabs/home/map/road/${userArea.area_id}`
              : "/tabs/home/premium/upgrade"
          }
        >
          <Stat
            value={`${getPercent(userArea.percent_covered_roads)}%`}
            label={"Roads"}
            valueColor={"#d9814a"}
            locked={isFree}
          />
        </Link>
        <Link
          to={
            !isFree
              ? `/tabs/home/map/trail/${userArea.area_id}`
              : "/tabs/home/premium/upgrade"
          }
        >
          <Stat
            value={`${getPercent(userArea.percent_covered_trails)}%`}
            label={"Trails"}
            valueColor={"#d9814a"}
            locked={isFree}
          />
        </Link>
      </div>
    </div>
  );
}
