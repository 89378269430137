import React from "react";
import { UserNeighbourhoodType } from "cp-server";
import styles from "./NeighbourhoodCard.module.css";
import { Config } from "framework";
import Stat from "../../../components/stat/Stat";
import LangManager from "../../../data/languages/LangManager";
import LangKeys from "../../../data/languages/LangKeys";
import classNames from "classnames";
import sharedStyles from "../../../styles/shared.module.css";
import { Link } from "react-router-dom";
import { getPercent } from "../../../data/utils/PercentageUtils";
import ShareIcon from "../../../assets/share.svg";
import ShareUtils from "../../../data/ShareUtils";
import { useAddEventMutation } from "../../../state/api/ApiSlice";
type Props = { userNeighbourhood: UserNeighbourhoodType };
export function NeighbourhoodCard({ userNeighbourhood }: Props) {
  const [trackEvent] = useAddEventMutation();

  const share = async (e) => {
    e.stopPropagation(); // prevents navigation to the user city
    trackEvent("share-user-neighbourhood");

    ShareUtils.sharePlaceImage({
      imgUrl: `${Config.getConfigItem("domain")}/neighbourhood_thumbs/${
        userNeighbourhood.user_neighbourhood_id
      }@2x.png`,
      leftValue: userNeighbourhood.name,
      rightValue: `${getPercent(userNeighbourhood.percent_complete)}%`,
      rightLabel: "Explored",
      leftLabel: "Neighbourhood",
    });
  };

  const landmarksText =
    userNeighbourhood.total_landmarks !== 0
      ? `${userNeighbourhood.landmarks_visited}/${userNeighbourhood.total_landmarks}`
      : "--";
  return (
    <Link
      to={`/tabs/home/neighbourhood-details/${userNeighbourhood.neighbourhood_id}`}
      className={styles.container}
    >
      <div className={styles.inner}>
        <div
          className={styles.thumbnail}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/neighbourhood_thumbs/${
              userNeighbourhood.user_neighbourhood_id
            }@2x.png)`,
          }}
        >
          <img
            src={ShareIcon}
            width={32}
            height={32}
            alt={"Share icon"}
            className={styles.shareIcon}
            onClick={share}
          />
        </div>
        <div className={classNames(sharedStyles.heading2, styles.name)}>
          {userNeighbourhood.name}
        </div>
        <div className={styles.statsContainer}>
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_EXPLORED)}
            value={`${getPercent(userNeighbourhood.percent_complete)}%`}
            valueColor="var(--orange-2)"
          />
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_LANDMARKS)}
            value={landmarksText}
            valueColor="var(--orange-2)"
            complete={
              parseInt(userNeighbourhood.landmarks_visited) ===
                userNeighbourhood.total_landmarks &&
              userNeighbourhood.total_landmarks !== 0
            }
          />
          <Stat
            label={LangManager.getLang(LangKeys.NEIGHBOURHOOD_ACTIVITIES)}
            value={`${userNeighbourhood.activities_count}`}
          />
        </div>
      </div>
    </Link>
  );
}
