import React, { useState } from "react";
import Content from "../../components/content/Content";
import styles from "./Dashboard.module.css";
import sharedStyles from "../../styles/shared.module.css";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import ActivitiesList from "./components/activity/activitieslist/ActivitiesList";
import DashboardLandmarks from "./components/landmarks/DashboardLandmarks";
import { TimePeriods, UserCityType } from "cp-server";
import Map from "./components/map/Map";
import DashboardAchievements from "./components/achievements/DashboardAchievements";
import ActivityAreaButtons from "./components/activity/ActivityAreaButtons";
import MapStat from "../../components/mapstat/MapStat";
import { useGetLandmarksWithVisitsInAreaQuery } from "../../state/api/ApiSlice";
import DashboardNeighbourhoods from "./components/neighbourhoods/DashboardNeighbourhoods";
import TimePeriodSelect from "../../components/timeperiodselect/TimePeriodSelect";
import DashboardLeaderboards from "./components/leaderboard/DashboardLeaderboards";
import SmallHeading from "../../components/headings/SmallHeading";
import classNames from "classnames";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import DashboardGroups from "./components/groups/DashboardGroups";
import DisplayIf from "../../components/display-if/DisplayIf";
import IsAccountReady from "./components/account-ready/IsAccountReady";
import moment from "moment";
import { getPercent } from "../../data/utils/PercentageUtils";
import FreeTrial from "./components/free-trial/FreeTrial";
import { isApp } from "../../platform/PlatformManager";
import DashboardPercentages from "./components/percentages/DashboardPercentages";
import { WaysType } from "cp-types";

function DashboardContent({
  userArea,
  isLocked,
}: {
  userArea: UserCityType;
  isLocked: boolean;
}) {
  const [period, setPeriod] = useState<TimePeriods>(TimePeriods.all);
  const history = useHistory();
  const { data: landmarks } = useGetLandmarksWithVisitsInAreaQuery({
    area_id: userArea.area_id,
  });

  function renderMap() {
    return (
      <>
        <Map
          userCity={userArea}
          key={userArea.user_city_id}
          period={period}
          waysType={WaysType.all}
        />
      </>
    );
  }

  function renderLeaderboards() {
    if (userArea.type !== "custom") {
      // Don't show leaderboards for custom areas as there would only be one user in the leaderboard!
      return <DashboardLeaderboards id={userArea.area_id} type={"area"} />;
    }
  }

  function renderGroups() {
    return (
      <DisplayIf condition={userArea.type !== "custom"}>
        <DashboardGroups
          area_id={userArea.area_id}
          id={userArea.area_id}
          type={"area"}
          area_name={userArea.name}
        />
      </DisplayIf>
    );
  }
  function renderLandmarks() {
    return <DashboardLandmarks userCity={userArea} />;
  }

  function renderActivities() {
    if (userArea) {
      return (
        <ActivitiesWithButtons userCity={userArea} key={userArea?.area_id} />
      );
    }
  }

  function renderLandmarkStat() {
    if (landmarks?.success) {
      const visitedLandmarks = landmarks.landmarks.filter(
        (l) => l.visits !== "0"
      );
      return `${visitedLandmarks.length} / ${landmarks.landmarks.length}`;
    }
    return "--";
  }

  function renderAchievements() {
    if (userArea) {
      return (
        <DashboardAchievements
          key={"achievements" + userArea.user_city_id}
          userCity={userArea}
        />
      );
    }
    return null;
  }

  function renderNeighbourhoods() {
    // Custom areas do not have neighbourhoods
    if (userArea && userArea.total_neighbourhoods !== "0") {
      return <DashboardNeighbourhoods userCity={userArea} />;
    }
  }

  function renderPercents() {
    return <DashboardPercentages userArea={userArea} />;
  }

  if (!userArea) {
    return null;
  }
  const neighbourhoodsValue =
    userArea.total_neighbourhoods !== "0"
      ? `${userArea.neighbourhoods_visited} / ${userArea.total_neighbourhoods}`
      : "--";
  return (
    <div className={styles.contentContainer}>
      <div className={styles.mapContainer}>
        <div
          className={styles.selectContainer}
          style={{ transform: "translateZ(0)" }}
        >
          <TimePeriodSelect period={period} setPeriod={setPeriod} />
        </div>
        {renderMap()}
        <div
          style={{
            height: 0,
            position: "relative",
            transform: "translateZ(0)",
          }}
        >
          <div className={styles.exploredBox}>
            {period === TimePeriods.monthly ? (
              <>
                <MapStat
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  value={`${getPercent(userArea.percent_covered_month)}%`}
                  complete={
                    parseInt(getPercent(userArea.percent_covered_month)) === 100
                  }
                />
              </>
            ) : null}
            {period === TimePeriods.year ? (
              <>
                <MapStat
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  value={`${getPercent(userArea.percent_covered_year)}%`}
                  complete={
                    parseInt(getPercent(userArea.percent_covered_year)) === 100
                  }
                />
              </>
            ) : null}
            {period === TimePeriods.all ? (
              <>
                <MapStat
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  value={`${getPercent(getPercent(userArea.percent_covered))}%`}
                  complete={
                    parseInt(getPercent(userArea.percent_covered)) === 100
                  }
                />
                <MapStat
                  label={`${LangManager.getLang(LangKeys.MAP_LANDMARKS)}`}
                  value={renderLandmarkStat()}
                  complete={
                    userArea.landmarks_visited === userArea.total_landmarks &&
                    userArea.total_neighbourhoods !== "0"
                  }
                />
                <MapStat
                  label={`${LangManager.getLang(LangKeys.MAP_NEIGHBOURHOOD)}`}
                  value={neighbourhoodsValue}
                  complete={
                    userArea.neighbourhoods_visited ===
                      userArea.total_neighbourhoods &&
                    userArea.total_neighbourhoods !== "0"
                  }
                />
              </>
            ) : null}
          </div>
        </div>

        {/*<Content>*/}
        {/*  <div className={classNames(styles.message, sharedStyles.text)}>*/}
        {/*    <img*/}
        {/*      src={require("../../assets/landing/exclaimation.png")}*/}
        {/*      width={24}*/}
        {/*      height={24}*/}
        {/*      style={{ marginTop: 4 }}*/}
        {/*      alt={"Exclamation point img"}*/}
        {/*    />*/}
        {/*    <div>*/}
        {/*      Due to map updates and a change to the way percentages are*/}
        {/*      calculated{" "}*/}
        {/*      <b>*/}
        {/*        your percentage might have increased or decreased very slightly*/}
        {/*      </b>{" "}*/}
        {/*      after 1st March*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Content>*/}
        <DisplayIf
          condition={
            moment().isBefore(moment("2024-07-01T23:59:43Z")) && isApp()
          }
        >
          <FreeTrial />
        </DisplayIf>
      </div>
      <Content>
        {!isLocked ? (
          <>
            {renderPercents()}
            {renderGroups()}
            {renderLeaderboards()}
            {renderNeighbourhoods()}
            {renderAchievements()}
            {renderLandmarks()}
            {renderActivities()}
          </>
        ) : (
          <div style={{ paddingBottom: 300 }}>
            <SmallHeading
              left={"Sorry. This area is not in your top three areas."}
            ></SmallHeading>
            <div
              className={classNames(sharedStyles.text)}
              style={{ marginBottom: 16 }}
            >
              You can see the <b>map</b> but the{" "}
              <b>leaderboards, landmarks, neighbourhoods</b> and{" "}
              <b>activities</b> are hidden
            </div>
            <Button
              color={"primary"}
              variant={"contained"}
              onClick={() => history.push("/tabs/home/premium/upgrade")}
            >
              Upgrade to see everything
            </Button>
          </div>
        )}
      </Content>
    </div>
  );
}

export default React.memo(DashboardContent);

const ActivitiesWithButtons = React.memo(function ({
  userCity,
}: {
  userCity: UserCityType;
}) {
  const [areaId, setAreaId] = useState(userCity.area_id);

  return (
    <div>
      <ActivityAreaButtons
        userCity={userCity}
        onChange={setAreaId}
        key={"buttonscontainer"}
      />
      <IsAccountReady showPlaceholder={true}>
        <ActivitiesList
          key={"activities" + areaId}
          filter={{ area_id: areaId || undefined }}
        />
      </IsAccountReady>
    </div>
  );
});
