import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import CityList from "./components/citylist/CityList";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import styles from "./Cities.module.css";
import CitySuggest from "../components/citysuggest/CitySuggest";
import { Helmet } from "react-helmet";
import SmallHeading from "../../components/headings/SmallHeading";
import { IonContent, IonSearchbar } from "@ionic/react";
import { useGetCitiesQuery } from "../../state/api/ApiSlice";
import { getResultsFromResponse } from "../../data/ApiData";
import { AreaType } from "cp-server";
import { useTranslation } from "react-i18next";
import WaitForData from "../../components/WaitForData";
import { Skeleton } from "@mui/material";

export default React.memo(function Cities() {
  const {
    data: citiesResponse,
    isLoading: isCitiesLoading,
  } = useGetCitiesQuery({});
  const [filter, setFilter] = useState("");
  const citiesUnfiltered = getResultsFromResponse(citiesResponse);
  const { t } = useTranslation();
  function renderCitiesList() {
    if (citiesResponse?.success) {
      const cities = citiesUnfiltered.filter((item) =>
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
      const citiesType = cities.filter((item) => item.type === "city");
      const areaType = cities.filter((item) => item.type === "area");
      const nationalParks = cities.filter(
        (item) => item.type === AreaType.national_park
      );
      const scenicAreas = cities.filter(
        (item) => item.type === AreaType.scenic_area
      );

      return (
        <div>
          <div className={styles.citiesHeader}>
            <SmallHeading left={t("explore.cities")} />
            <CitySuggest />
          </div>

          <CityList cities={citiesType} />
          <div style={{ marginTop: 16 }}>
            <SmallHeading left={t("explore.areas")} />
          </div>
          <CityList cities={areaType} />

          <div style={{ marginTop: 16 }}>
            <SmallHeading
              left={t("explore.nationalParks")}
              rightElement={
                <div className={styles.newForSubs}>
                  {t("explore.subscribers")}
                </div>
              }
            />
          </div>
          <CityList cities={nationalParks} />
          <div style={{ marginTop: 16 }}>
            <SmallHeading
              left={t("explore.scenicAreas")}
              rightElement={
                <div className={styles.newForSubs}>
                  {" "}
                  {t("explore.subscribers")}
                </div>
              }
            />
          </div>
          <CityList cities={scenicAreas} />
        </div>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>City Painter - Explore</title>
      </Helmet>
      <Header title={LangManager.getLang(LangKeys.CITIES_BROWSE)} />
      <IonContent>
        <Content>
          <IonSearchbar
            placeholder={"find"}
            onIonInput={(e) => setFilter((e.target.value as string) || "")}
            value={filter}
            style={{ marginTop: 16 }}
          ></IonSearchbar>
          <WaitForData
            isLoading={isCitiesLoading}
            loadingElement={
              <>
                <div className={styles.citiesHeader}>
                  <SmallHeading left={t("explore.cities")} />
                  <CitySuggest />
                </div>
                <Skeleton variant="rectangular" height={"50vh"} />
              </>
            }
          >
            {renderCitiesList()}
          </WaitForData>
        </Content>
      </IonContent>
    </>
  );
});
