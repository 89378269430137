import React from "react";
import Header from "../components/Header";
import { IonContent } from "@ionic/react";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { useGetLandmarksWithVisitsInAreaQuery } from "../../state/api/ApiSlice";
import { useParams } from "react-router-dom";
import Landmark from "../components/landmark/Landmark";
import styles from "./Landmarks.module.css";
import Map from "../dashboard/components/map/Map";
import SmallHeading from "../../components/headings/SmallHeading";
import Content from "../../components/content/Content";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import { TimePeriods, WaysType } from "cp-types";
import { useGetUserCity } from "../../data/hooks/useGetUserCity";

function Landmarks() {
  const { area_id } = useParams<{ area_id: string }>();
  const { data: landmarksResponse } = useGetLandmarksWithVisitsInAreaQuery({
    area_id,
  });
  const limit = AccountTierFunctions.getNumberOfLandmarks();
  const userCity = useGetUserCity(area_id);

  const landmarks = landmarksResponse?.success
    ? [...landmarksResponse.landmarks]
    : [];
  landmarks.sort((a, b) => {
    return parseInt(a.visits || "") < parseInt(b.visits || "") ? 1 : -1;
  });
  const slicedLandmarks = landmarks.slice(0, limit);

  function getTitle() {
    const landmarkCopy = LangManager.getLang(LangKeys.LANDMARKS_HEADER);
    return userCity ? `${userCity.name} ${landmarkCopy}` : landmarkCopy;
  }

  return (
    <>
      <Header title={getTitle()} />
      <IonContent>
        {userCity ? (
          <Map
            userCity={userCity}
            period={TimePeriods.all}
            waysType={WaysType.all}
          />
        ) : null}
        <Content>
          <SmallHeading left={"Landmark Visits"} />
          <div className={styles.landmarkGrid}>
            {slicedLandmarks.map((l) => {
              return (
                <>
                  <Landmark landmark={l} />
                  <span className={styles.detailsText}>{`${
                    l.visits
                  } ${LangManager.getLang(
                    l.visits === "1"
                      ? LangKeys.DASHBOARD_LANDMARKS_VISIT
                      : LangKeys.DASHBOARD_LANDMARKS_VISITS
                  )}`}</span>
                </>
              );
            })}
          </div>
        </Content>
      </IonContent>
    </>
  );
}

export default React.memo(Landmarks);
