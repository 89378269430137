import { MapStyleParams } from "./MapStyles";
import { LayerSpecification } from "maplibre-gl";
import { WaysType } from "cp-types";

export function roadsAndTrails(params: MapStyleParams): LayerSpecification[] {
  const roadZoomWidths = [10, 0.48, 11, 0.8, 12, 1.5, 13, 3.5, 14, 7, 15, 15];
  let roadColor = params.unfilled ? "#C2D3DE" : "#195280";
  let offRoadColor = params.unfilled ? "#B5DEDE" : "#18a0a0";
  if (params.simple) {
    // Show both road and trail as the same blue colour because it's easier to distinguish from the heatmaps orange colour
    // Remember that the aim of the simple map is to make it easier for the user to spot incomplete paths
    roadColor = "#195280";
    offRoadColor = "#195280";
  }

  const offRoadOutline: LayerSpecification = {
    id: "off-roads-outline",
    type: "line",
    minzoom: 13,
    source: "trails",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": 1,
      "line-gap-width": [
        "interpolate",
        ["linear"],
        ["zoom"],
        ...roadZoomWidths,
      ],
      "line-color": "#000000",
    },
  };
  const offRoads: LayerSpecification = {
    id: "off-roads",
    type: "line",
    source: "trails",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": ["interpolate", ["linear"], ["zoom"], ...roadZoomWidths],
      "line-color": offRoadColor,
    },
  };
  const roadsOutline: LayerSpecification = {
    id: "roads-outline",
    type: "line",
    source: "roads",
    minzoom: 13,
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": 1,
      "line-gap-width": [
        "interpolate",
        ["linear"],
        ["zoom"],
        ...roadZoomWidths,
      ],
      "line-color": "#000000",
    },
  };
  const roads: LayerSpecification = {
    id: "roads",
    type: "line",
    source: "roads",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": ["interpolate", ["linear"], ["zoom"], ...roadZoomWidths],
      "line-color": roadColor,
    },
  };

  if (params.simple) {
    // Dont include outlines for the simple map
    if (params.ways === WaysType.trail) {
      return [offRoads];
    } else if (params.ways === WaysType.road) {
      return [roads];
    }
    return [offRoads, roads];
  }

  if (params.ways === WaysType.trail) {
    return [offRoadOutline, offRoads];
  } else if (params.ways === WaysType.road) {
    return [roadsOutline, roads];
  }

  return [offRoadOutline, offRoads, roadsOutline, roads];
}

export function areaBackground(): LayerSpecification {
  return {
    id: "area-fill",
    type: "fill",
    source: "area",
    paint: {
      "fill-color": "#f8f8f8",
    },
  };
}
export function areaOutline(): LayerSpecification {
  return {
    id: "area-outline",
    type: "line",
    source: "area",
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-width": 2,
      "line-color": "#4e5a66",
    },
  };
}

export function roadAndTrailLabels(): LayerSpecification {
  return {
    id: "labels",
    type: "symbol",
    source: "roads",
    layout: {
      "symbol-placement": "line",
      "text-size": 12,
      "text-font": ["default"],
      "text-field": ["get", "n"], // n  = name
    },
    paint: {
      "text-color": "#000000",
      "text-halo-color": "#ffffff",
      "text-halo-width": 2,
    },
    minzoom: 14,
  };
}
export function osmMaps(params: MapStyleParams): LayerSpecification[] {
  return [
    {
      id: "osm_lowzoom",
      type: "raster",
      source: "osm",
      minzoom: 0,
      maxzoom: 13,
      paint: {
        "raster-opacity": !params.simple ? 0.3 : 0.1, // Only want a very faint map layer when showing the 'Simple' map
      },
    },
    {
      id: "osm",
      type: "raster",
      source: "osm",
      minzoom: 13,
      paint: {
        "raster-opacity": !params.simple ? 0.75 : 0.3, // Only want a very faint map layer when showing the 'Simple' map
      },
    },
    // this is to mask the osm layer at higher zooms
    {
      id: "area-mask",
      type: "fill",
      source: "area_mask",
      paint: {
        "fill-color": "#f8f8f8",
        "fill-opacity": 0.7,
      },
    },
  ];
}

export function neighbourhoodMask(): LayerSpecification[] {
  return [
    {
      id: "neighbourhood-mask",
      type: "fill",
      source: "neighbourhood_mask",
      paint: {
        "fill-color": "#f8f8f8",
        "fill-opacity": 0.95,
      },
    },
    {
      id: "neighbourhood-outline",
      type: "line",
      source: "neighbourhood_mask",
      paint: {
        "line-color": "#000000",
        "line-width": 2,
      },
    },
  ];
}

export function neighbourhoods(): LayerSpecification[] {
  return [
    {
      id: "neighbourhoods",
      type: "fill",
      source: "neighbourhoods",

      paint: {
        "fill-color": "#d9814a",
        "fill-opacity": ["get", "percentComplete"],
      },
    },
    {
      id: "neighbourhoods-outline",
      type: "line",
      source: "neighbourhoods",

      paint: {
        "line-color": "#666666",
        "line-width": 1,
      },
    },
  ];
}

export function heatmap(): LayerSpecification {
  return {
    id: "heatmap",
    type: "raster",
    source: "heatmap",
  };
}

export function backgroundHeatmap() {
  return {
    id: "heatmap",
    type: "raster",
    source: "heatmap",
    paint: {
      "raster-opacity": 0.3,
    },
  };
}
