import React from "react";
import styles from "./Activity.module.css";
import moment from "moment";
import LangManager from "../../../../data/languages/LangManager";
import LangKeys from "../../../../data/languages/LangKeys";
import Stat from "../../../../components/stat/Stat";
import { getDistance } from "../../../../data/utils/formating/DistanceFormating";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Config } from "framework";
import classNames from "classnames";
import sharedStyles from "../../../../styles/shared.module.css";
import type {
  ActivityType,
  LandmarkType,
  NeighbourhoodSimple,
} from "cp-server";
import Landmark from "../../../components/landmark/Landmark";
import { getDuration } from "../../../../data/utils/formating/DateTimeFormatting";
import Neighbourhood from "../../../components/neighbourhood/Neighbourhood";
import LandmarkUtils from "../../../../data/utils/landmarks/LandmarkUtils";
import NeighbourhoodUtils from "../../../../data/utils/neighbourhoods/NeighbourhoodUtils";
import ShareIcon from "../../../../assets/share.svg";
import Logo from "../../../../assets/logo-shadow.png";
import { getPercent } from "../../../../data/utils/PercentageUtils";
import ShareUtils from "../../../../data/ShareUtils";
import { useAddEventMutation } from "../../../../state/api/ApiSlice";

type Props = {
  data: ActivityType;
  percent?: string;
};

export default function Activity(props: Props) {
  const history = useHistory();
  const goToActivity = () => {
    history.push(`/tabs/home/activity/${props.data.id}`);
  };
  const [trackEvent] = useAddEventMutation();

  const share = async (e) => {
    e.stopPropagation(); // prevents navigation to the user city

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    const cpLogo = new Image();
    cpLogo.src = Logo;
    cpLogo.crossOrigin = "anonymous";

    const img = new Image();
    img.src = `${Config.getConfigItem("domain")}/activities/${
      props.data.id
    }.png`;
    img.crossOrigin = "anonymous";
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      const textStart = img.height - 44;
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      // // Top shaded rectangle
      // const gradTop = ctx.createLinearGradient(0, 0, 0, 30);
      // gradTop.addColorStop(1, "rgba(50,50,50,0)");
      // gradTop.addColorStop(0, "rgba(50,50,50,0.75)");
      //
      // // Fill rectangle with gradient
      // ctx.fillStyle = gradTop;
      // ctx.fillRect(0, 0, canvas.width, 30);
      ctx.drawImage(cpLogo, 2, 2, 175, 23);

      // Bottom shaded rectangle
      const grad = ctx.createLinearGradient(
        0,
        canvas.height - 80,
        0,
        canvas.height
      );
      grad.addColorStop(0, "rgba(50,50,50,0)");
      grad.addColorStop(0.3, "rgba(50,50,50,0.6)");
      grad.addColorStop(1, "rgba(50,50,50,1)");

      // Fill rectangle with gradient
      ctx.fillStyle = grad;
      ctx.fillRect(0, canvas.height - 80, canvas.width, 80);

      ctx.fillStyle = "white";
      ctx.font = "bold 18px Montserrat";
      ctx.fillText("Distance", 20, textStart + 6);

      ctx.fillStyle = "#d9814a";
      ctx.font = "bold 24px Montserrat";

      ctx.fillText(getDistance(props.data.distance), 20, textStart + 32);

      ctx.fillStyle = "white";
      ctx.font = "bold 18px Montserrat";
      ctx.fillText("New Ground", img.width - 160, textStart + 6);

      ctx.fillStyle = "#cc0cf0";
      ctx.font = "bold 24px Montserrat";
      ctx.strokeStyle = "#aaaaaa";
      ctx.lineWidth = 3;
      ctx.strokeText(
        `${getPercent(props.data.percent_new)}%`,
        img.width - 160,
        textStart + 32
      );
      ctx.fillText(
        `${getPercent(props.data.percent_new)}%`,
        img.width - 160,
        textStart + 32
      );
      console.log("sharing");
      const base64 = canvas.toDataURL("image/png");
      ShareUtils.share({
        fileName: `${props.data.name}.png`,
        b64Image: base64.replace("data:image/png;base64,", ""),
      });
      trackEvent("share-activity");
    };
  };

  function noneVisitedMessage(
    landmarks: Array<LandmarkType>,
    neighbourhoods: Array<NeighbourhoodSimple>
  ) {
    // Removes nulls (Can happen due to the way the server processes these items) // TODO

    if (!landmarks.length && !neighbourhoods.length)
      return (
        <div>
          <div
            className={classNames(
              sharedStyles.heading4,
              styles.noLandmarksText
            )}
          >
            {LangManager.getLang(LangKeys.ACTIVITIES_NONE_VISITED)}
          </div>
        </div>
      );
  }
  function renderLandmarks(landmarks: Array<LandmarkType>, count: number) {
    // Remove nulls (Can happen due to the way the server processes landmarks) // TODO
    if (!landmarks.length) {
      return null;
    }
    const firstLandmarks = landmarks.slice(0, count);
    const all = firstLandmarks.length === landmarks.length;
    return (
      <div className={styles.landmarksContainer}>
        <div className={classNames(sharedStyles.heading4, styles.visitedText)}>
          {LangManager.getLang(LangKeys.ACTIVITIES_LANDMARKS_VISITED)}
          {!all ? ` (${firstLandmarks.length} of ${landmarks.length})` : ""}
        </div>
        {firstLandmarks.map((l) => {
          return (
            <Landmark landmark={l} key={`${props.data.id}_${l.landmark_id}`} />
          );
        })}
      </div>
    );
  }

  function renderNeighbourhoods(
    neighbourhoods: Array<NeighbourhoodSimple>,
    count: number
  ) {
    // Remove nulls (Can happen due to the way the server processes neighbourhoods) // TODO
    if (!neighbourhoods.length) {
      return null;
    }
    const firstNeighbourhoods = neighbourhoods.slice(0, count);
    const all = firstNeighbourhoods.length === neighbourhoods.length;
    return (
      <div className={styles.neighbourhoods}>
        <div className={classNames(sharedStyles.heading4, styles.visitedText)}>
          {LangManager.getLang(LangKeys.ACTIVITIES_NEIGHBOURHOODS_VISITED)}
          {!all
            ? ` (${firstNeighbourhoods.length} of ${neighbourhoods.length})`
            : ""}
        </div>
        {firstNeighbourhoods.map((n) => {
          return <Neighbourhood neighbourhood={n} key={n.neighbourhood_id} />;
        })}
      </div>
    );
  }

  function renderVisits(activity: ActivityType) {
    // Removes nulls (Can happen due to the way the server processes these items) // TODO
    const filteredLandmarks = LandmarkUtils.removeDuplicates(
      activity.landmarks
    ).filter((l) => l);
    const filteredNeighbourhoods = NeighbourhoodUtils.removeDuplicates(
      activity.neighbourhoods
    ).filter((n) => n);

    const neighbourhoodsAllowed = 4 + Math.max(3 - filteredLandmarks.length, 0);
    const landmarksAllowed = 4 + Math.max(3 - filteredNeighbourhoods.length, 0);

    return (
      <>
        {noneVisitedMessage(filteredLandmarks, filteredNeighbourhoods)}
        {renderLandmarks(filteredLandmarks, landmarksAllowed)}
        {renderNeighbourhoods(filteredNeighbourhoods, neighbourhoodsAllowed)}
      </>
    );
  }

  const activity = props.data;
  const date = moment(activity.start_time).format("@ HH:mm MMMM Do YYYY");
  const duration = getDuration(activity.moving_time);
  const percentIsLoading = !activity.percent_new && !props.percent;
  const percentNew: string = parseFloat(
    activity.percent_new || props.percent || "0"
  ).toFixed(1);
  const percentOld: string = (100 - parseFloat(percentNew)).toFixed(1);
  return (
    <div className={styles.container} onClick={goToActivity}>
      <img src={Logo} width={0} height={0} alt={"City Painter logo"} />
      <div className={styles.inner}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${Config.getConfigItem(
              "domain"
            )}/activities/${activity.id}.png)`,
          }}
        >
          <img
            src={ShareIcon}
            width={32}
            height={32}
            alt={"Share icon"}
            className={styles.shareIcon}
            onClick={share}
          />
        </div>
        <div className={styles.detailsContainer}>
          <div
            className={classNames(
              activity.name.length > 80
                ? sharedStyles.heading4
                : sharedStyles.heading2,
              styles.title
            )}
          >
            {activity.name}
          </div>
          <div className={styles.date}>{date}</div>
          <div className={styles.details}>
            <div className={styles.statsContainer}>
              <Stat
                label={LangManager.getLang(LangKeys.ACTIVITY_NEW_GROUND)}
                value={`${percentNew}%`}
                valueColor="var(--purple)"
                loading={percentIsLoading}
              />
              <Stat label={"Distance"} value={getDistance(activity.distance)} />
              <Stat
                label={LangManager.getLang(LangKeys.ACTIVITY_REVISITED)}
                value={`${percentOld}%`}
                valueColor="var(--orange-2)"
                loading={percentIsLoading}
              />
              <Stat label={"Time"} value={duration} />
            </div>
          </div>
          <div className={styles.divider} />
        </div>
        <div className={styles.visitsContainer}>{renderVisits(activity)}</div>
      </div>
    </div>
  );
}
