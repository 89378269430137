import React, { useEffect } from "react";
import Header from "../components/Header";
import Content from "../../components/content/Content";
import UserCityList from "./components/citylist/UserCityList";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { Helmet } from "react-helmet";
import CreateAreaLink from "../components/createarealink/CreateAreaLink";
import { useGetUserCitiesQuery } from "../../state/api/ApiSlice";
import { IonContent } from "@ionic/react";
import { AreaType } from "cp-server";
import SmallHeading from "../../components/headings/SmallHeading";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import { FacebookIcon, FacebookShareButton } from "react-share";
import AreasProcessingNote from "../components/areas-processing-note/AreasProcessingNote";
import WaitForData from "../../components/WaitForData";
import { Skeleton } from "@mui/material";

export default function UserCities() {
  const {
    data: userCities,
    isLoading: isUserCitiesLoading,
  } = useGetUserCitiesQuery({});
  function renderCitiesList() {
    if (userCities?.success) {
      return <UserCityList cities={userCities.cities} />;
    }
  }

  /**
   * Returns true when the user has a custom city
   */
  function getShouldShowEdit() {
    if (userCities?.success) {
      const hasCustomArea = userCities.cities
        .map((c) => c.type)
        .includes(AreaType.custom);
      return hasCustomArea;
    }
    return false;
  }

  return (
    <>
      <Helmet>
        <title>City Painter - My cities and areas</title>
      </Helmet>
      <Header
        title={LangManager.getLang(LangKeys.CITIES_HEADER)}
        rightContent={<CreateAreaLink edit={getShouldShowEdit()} />}
      />
      <IonContent>
        <Content>
          <SmallHeading
            left={
              AccountTierFunctions.accountIsFree() ? "Top 3 areas" : "All Areas"
            }
          />
          <AreasProcessingNote />
          <WaitForData
            isLoading={isUserCitiesLoading}
            loadingElement={
              <>
                <Skeleton
                  variant="rectangular"
                  height={"50vh"}
                  style={{ marginBottom: 20 }}
                />
                <Skeleton variant="rectangular" height={"50vh"} />
              </>
            }
          >
            {renderCitiesList()}
          </WaitForData>
        </Content>
      </IonContent>
    </>
  );
}
