import { Config } from "framework";
import { TimePeriods, WaysType } from "cp-types";

export function heatmapSource(
  user_city_id: string,
  period: TimePeriods,
  ways: WaysType
) {
  return {
    heatmap: {
      type: "raster",
      tiles: [
        `${Config.getConfigItem(
          "domain"
        )}/heatmap/${period}/${user_city_id}/${ways}/{z}/{x}/{y}.png`,
      ],
      tileSize: 512,
    },
  };
}

export function activityHeatmapSource(activity_id) {
  return {
    heatmap: {
      type: "raster",
      tiles: [
        `${Config.getConfigItem(
          "domain"
        )}/activity_tiles/${activity_id}/{z}/{x}/{y}.png`,
      ],
      tileSize: 512,
    },
  };
}
