import React from "react";
import styles from "./Settings.module.css";
import { useDispatch } from "react-redux";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import Content from "../../components/content/Content";
import { SetSettingsType } from "cp-server";
import sharedStyles from "../../styles/shared.module.css";
import classNames from "classnames";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import EmailSettings from "./components/EmailSettings";
import { Button } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { IonContent, IonToggle } from "@ionic/react";
import {
  useGetSettingsQuery,
  useSetSettingsMutation,
  useSubscriptionGetPortalUrlMutation,
  useSubscriptionGetQuery,
  useUserQuery,
} from "../../state/api/ApiSlice";
import PremiumMessage from "../../components/premium/PremiumMessage";
import moment from "moment";
import SmallHeading from "../../components/headings/SmallHeading";
import { PopupTypes, showPopup } from "../../state/popups/PopupsActions";
import UserUtils from "../../data/utils/UserUtils";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/styles";
import { theme } from "../../App";
import ActivityDescriptionSettings from "./components/ActivityDescriptionSettings";

export default function Settings() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: settings } = useGetSettingsQuery();
  const [setSettings] = useSetSettingsMutation();
  const { data: userResponse } = useUserQuery({});
  const saveSettings = (settings: SetSettingsType) => {
    setSettings({ settings });
  };

  const logout = () => {
    UserUtils.logout();
  };

  const renderEmailSettings = () => {
    if (settings?.success) {
      return (
        <EmailSettings
          {...settings.settings}
          saveSettings={saveSettings}
          key={String(settings.settings)}
        />
      );
    }
    return null;
  };

  const renderStravaActivityDescriptionSettings = () => {
    if (settings?.success) {
      return (
        <ActivityDescriptionSettings
          {...settings.settings}
          saveSettings={saveSettings}
          key={String(settings.settings)}
        />
      );
    }
  };
  const closeAccount = () => {
    dispatch(showPopup({ popupType: PopupTypes.DELETE_ACCOUNT_CONFIRM }));
  };

  const updateName = () => {
    dispatch(showPopup({ popupType: PopupTypes.NAME }));
  };

  const user = userResponse?.success ? userResponse.user : undefined;

  return (
    <>
      <Helmet>
        <title>City Painter - Settings</title>
      </Helmet>
      <Header title={LangManager.getLang(LangKeys.SETTINGS_HEADER)} />
      <IonContent>
        <Content>
          <SmallHeading left={t("settings.personalDetails")} />
          <div className={classNames(sharedStyles.heading4, styles.detail)}>
            Email: {`${user?.email}`}
          </div>
          <div className={classNames(sharedStyles.heading4, styles.detail)}>
            Name: {`${user?.first_name} ${user?.last_name}`}
          </div>
          <ThemeProvider theme={theme}>
            <Button
              onClick={updateName}
              variant={"contained"}
              style={{ width: 150, marginTop: 10 }}
              color={"primary"}
            >
              Change Name
            </Button>
          </ThemeProvider>
          <div style={{ height: 40 }} />

          {/*Email settings*/}
          <SmallHeading
            left={LangManager.getLang(LangKeys.SETTINGS_EMAIL_HEADER)}
          />
          {renderEmailSettings()}
          <div style={{ height: 40 }} />

          <SmallHeading left={"Strava activity description"} />

          {renderStravaActivityDescriptionSettings()}
          <div style={{ height: 40 }} />
          {/*Subscription settings*/}
          <SmallHeading left={"Subscription"} />
          <Subscription />
          <div style={{ height: 40 }} />

          {/*Account settings*/}
          <SmallHeading left={LangManager.getLang(LangKeys.SETTINGS_ACCOUNT)} />
          <Button
            variant={"contained"}
            className={styles.logoutButton}
            onClick={logout}
          >
            Log out
          </Button>
          <div style={{ height: 40 }} />
          <Link to={"/t_and_c"} style={{ marginBottom: 20 }}>
            Terms and Conditions
          </Link>

          <Link to={"/privacy_policy"}>Privacy Policy</Link>
          {/* Todo make this dynamic */}
          <div style={{ marginTop: 20 }}>Version: 1.0.43</div>
          <SmallHeading
            left={LangManager.getLang(LangKeys.SETTINGS_CLOSE_ACCOUNT)}
          />
          <Button
            onClick={closeAccount}
            style={{ marginBottom: 20, width: 200 }}
            variant={"contained"}
          >
            {LangManager.getLang(LangKeys.SETTINGS_CLOSE_ACCOUNT)}
          </Button>
        </Content>
      </IonContent>
    </>
  );
}

const Subscription = function () {
  const [gotoPortal, {}] = useSubscriptionGetPortalUrlMutation();
  const {
    data: subscriptionResponse,
    isLoading: subscriptionIsLoading,
  } = useSubscriptionGetQuery();
  // portal redirect is a 'side effect' of the endpoint. See the endpoint module
  const subscription =
    subscriptionResponse?.success && subscriptionResponse.subscription;

  function manageSubscription(vendor: "apple" | "stripe" | "revcat") {
    if (vendor === "apple") {
      window.location.href = "https://apps.apple.com/account/subscriptions";
    }
    if (vendor === "stripe") {
      gotoPortal({});
    }
    if (vendor === "revcat") {
      window.location.href =
        "https://play.google.com/store/account/subscriptions";
    }
  }

  if (subscription) {
    const endDate = moment(subscription.end_timestamp).format("DD MMM YYYY");
    const vendor = subscription.vendor;
    return (
      <div>
        <div
          className={classNames(sharedStyles.text, styles.subscriptionMessage)}
        >
          Your subscription renewal date is {endDate}
        </div>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => manageSubscription(vendor)}
          className={styles.subscriptionButton}
        >
          View or manage Subscription
        </Button>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 16 }}>
      <PremiumMessage text={"You are currently on a\nfree subscription"} />
    </div>
  );
};
