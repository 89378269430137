import Header from "../components/Header";
import Content from "../../components/content/Content";
import styles from "../neighbourhoods/Neighbourhoods.module.css";
import sharedStyles from "../../styles/shared.module.css";
import CPMap from "../../components/map/CPMap";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getResultsFromResponse } from "../../data/ApiData";
import mask from "@turf/mask";
import { Polygon } from "geojson";
import { MapStyles } from "../../components/map/MapStyles";
import { heatmapSource } from "../../components/map/Sources";
import ActivitiesList from "../dashboard/components/activity/activitieslist/ActivitiesList";
import SmallHeading from "../../components/headings/SmallHeading";
import LangManager from "../../data/languages/LangManager";
import LangKeys from "../../data/languages/LangKeys";
import { LandmarksSummary } from "../dashboard/components/landmarks/LandmarksSummary";
import scale from "@turf/transform-scale";
import MapStat from "../../components/mapstat/MapStat";
import { LandmarkType, TimePeriods, UserNeighbourhoodType } from "cp-server";
import {
  DOMAIN,
  useGetActivitiesQuery,
  useGetLandmarksWithVisitsByNeighbourhoodQuery,
  useGetUserCitiesQuery,
  useGetUserNeighbourhoodQuery,
} from "../../state/api/ApiSlice";
import { IonContent, IonToggle } from "@ionic/react";
import { isAtBottom } from "../../App";
import TimePeriodSelect from "../../components/timeperiodselect/TimePeriodSelect";
import DashboardLeaderboards from "../dashboard/components/leaderboard/DashboardLeaderboards";
import classNames from "classnames";
import DashboardGroups from "../dashboard/components/groups/DashboardGroups";
import { getPercent } from "../../data/utils/PercentageUtils";
import { WaysType } from "cp-types";

function renderMapStats(un: UserNeighbourhoodType, period: TimePeriods) {
  const landmarks = `${un.landmarks_visited}/${un.total_landmarks}`;
  const explored = getPercent(un.percent_complete);

  return (
    <div
      style={{ height: 0, position: "relative", transform: "translateZ(0)" }}
    >
      <div className={styles.exploredBox}>
        {
          {
            [TimePeriods.all]: (
              <>
                <MapStat
                  value={`${explored}%`}
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  complete={parseInt(un.percent_complete) === 100}
                />
                <div style={{ height: 6 }} />
                <MapStat
                  value={landmarks}
                  label={`${LangManager.getLang(LangKeys.MAP_LANDMARKS)} `}
                  complete={
                    parseInt(un.landmarks_visited) === un.total_landmarks
                  }
                />
              </>
            ),
            [TimePeriods.monthly]: (
              <>
                <MapStat
                  value={`${getPercent(un.percent_covered_month)}%`}
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  complete={
                    parseInt(getPercent(un.percent_covered_month)) === 100
                  }
                />
              </>
            ),
            [TimePeriods.year]: (
              <>
                <MapStat
                  value={`${getPercent(un.percent_covered_year)}%`}
                  label={`${LangManager.getLang(LangKeys.MAP_EXPLORED)}`}
                  complete={
                    parseInt(getPercent(un.percent_covered_year)) === 100
                  }
                />
              </>
            ),
          }[period]
        }
      </div>
    </div>
  );
}

// Renders map only
function RenderMap({
  userNeighbourhood,
  landmarks,
  simple,
}: {
  userNeighbourhood: UserNeighbourhoodType;
  landmarks: Array<LandmarkType>;
  simple: boolean;
}) {
  const [period, setPeriod] = useState<TimePeriods>(TimePeriods.all);
  const neighbourhoodGeoMask = mask({
    type: "Feature",
    geometry: userNeighbourhood.polygon as Polygon,
    properties: {
      name: userNeighbourhood.name,
      percentComplete: parseFloat(userNeighbourhood.percent_complete) / 100,
    },
  });
  return (
    <div className={styles.mapContainer}>
      <div className={styles.selectContainer}>
        <TimePeriodSelect period={period} setPeriod={setPeriod} />
      </div>
      <CPMap
        key={`${userNeighbourhood.user_neighbourhood_id}${period}${simple}`}
        mapStyle={MapStyles.NeighbourhoodDetails}
        unfilled_map
        simple={simple}
        area_id={userNeighbourhood.area_id}
        boundingBox={
          /* Allow user to scroll past the bounds of just the neighbourhood*/
          scale(userNeighbourhood.bounding_box, 3, { origin: "center" })
        }
        initialBounds={userNeighbourhood.bounding_box}
        /* We only want to show landmarks for the all time map. There aren't landmark stats for the monthly map*/
        landmarks={period === TimePeriods.all ? landmarks : []}
        className={styles.map}
        sources={{
          ...heatmapSource(
            userNeighbourhood.user_city_id,
            period,
            WaysType.all
          ),
          neighbourhood_mask: {
            type: "geojson",
            data: neighbourhoodGeoMask,
          },
        }}
      />
      {renderMapStats(userNeighbourhood, period)}
    </div>
  );
}

export default function NeighbourhoodDetails() {
  const { id: neighbourhoodId } = useParams<{ id: string }>();

  const [simpleMap, setSimpleMap] = useState(false);

  const { data: userNeighbourhoodResponce } = useGetUserNeighbourhoodQuery({
    neighbourhood_id: neighbourhoodId,
  });
  const {
    data: landmarksResponse,
  } = useGetLandmarksWithVisitsByNeighbourhoodQuery({
    neighbourhood_id: neighbourhoodId,
  });
  const { data: activitiesResponse } = useGetActivitiesQuery({
    page: "1",
    neighbourhood_id: neighbourhoodId,
    page_size: "6",
  });

  // Needed to determine whether the neighbourhood is in a custom area
  const { data: userCities } = useGetUserCitiesQuery({});

  const landmarks = getResultsFromResponse(landmarksResponse);
  const activities = getResultsFromResponse(activitiesResponse);
  function renderContent() {
    if (userNeighbourhoodResponce?.success && userCities?.success) {
      const userNeighbourhood = userNeighbourhoodResponce.user_neighbourhood;

      // Don't show leaderboards for neighbourhoods in custom areas as there would only be one user in the leaderboard!
      const inCustomArea =
        userCities.cities.find((uc) => uc.area_id === userNeighbourhood.area_id)
          ?.type === "custom";
      return (
        <>
          {/*  // Make sure not to render the map before the landmarks data is ready... Otherwise they won't show at all */}
          {landmarksResponse?.success ? (
            <RenderMap
              userNeighbourhood={userNeighbourhood}
              landmarks={landmarks}
              simple={simpleMap}
            />
          ) : null}
          <Content>
            <div className={styles.highlightBar}>
              <div className={styles.checkbox}>
                <IonToggle
                  checked={simpleMap}
                  onIonChange={(e) => {
                    setSimpleMap(e.detail.checked);
                  }}
                ></IonToggle>
                <div className={styles.highlightLabel}>
                  {LangManager.getLang(
                    LangKeys.NEIGHBOURHOODS_HIGHLIGHT_INCOMPLETE_PATHS
                  )}
                </div>
              </div>
              {!simpleMap ? null : (
                <div
                  className={classNames(
                    sharedStyles.text,
                    styles.moreDetailsMessage
                  )}
                >
                  Still cant find the missing bits?{" "}
                  <a
                    target={"_blank"}
                    href={`${DOMAIN}/neighbourhood_raw_image/${userNeighbourhood?.user_neighbourhood_id}`}
                  >
                    View more details
                  </a>
                </div>
              )}
            </div>
            {!inCustomArea ? (
              <DashboardGroups
                area_id={userNeighbourhood.area_id}
                area_name={userNeighbourhood.name}
                type={"neighbourhood"}
                id={neighbourhoodId}
              />
            ) : null}
            {!inCustomArea ? (
              <DashboardLeaderboards
                type={"neighbourhood"}
                id={neighbourhoodId}
              />
            ) : null}
            <LandmarksSummary activities={activities} landmarks={landmarks} />
            <SmallHeading
              left={`${LangManager.getLang(
                LangKeys.NEIGHBOURHOOD_ACTIVITIES_HEADER
              )} | ${userNeighbourhood.activities_count}`}
            />
            <ActivitiesList
              filter={{ neighbourhood_id: userNeighbourhood.neighbourhood_id }}
              key={userNeighbourhood.neighbourhood_id}
            />
          </Content>
        </>
      );
    }
  }

  function getHeaderTitle() {
    if (userNeighbourhoodResponce?.success) {
      const userNeighbourhood = userNeighbourhoodResponce.user_neighbourhood;
      return userNeighbourhood.name;
    }
    return "Neighbourhood details";
  }
  return (
    <>
      <Header title={getHeaderTitle()} />
      <IonContent scrollEvents={true} onIonScroll={isAtBottom}>
        {/* Scroll needed for activity list infinite feed*/}
        {renderContent()}
      </IonContent>
    </>
  );
}
