import React from "react";
import Header from "../components/Header";
import { Helmet } from "react-helmet";
import { IonContent } from "@ionic/react";
import { Link, useParams } from "react-router-dom";
import { useGetUserCity } from "../../data/hooks/useGetUserCity";
import DisplayIf from "../../components/display-if/DisplayIf";
import Map from "../dashboard/components/map/Map";
import { TimePeriods, WaysType } from "cp-types";
import SmallHeading from "../../components/headings/SmallHeading";
import styles from "./TrailRoadMap.module.css";
import Stat from "../../components/stat/Stat";
import { getPercent } from "../../data/utils/PercentageUtils";
import AccountTierFunctions from "../../data/account/AccountTierFunctions";
import Content from "../../components/content/Content";

export default function TrailRoadMap() {
  const { area_id, way_type } = useParams<{
    area_id: string;
    way_type: WaysType;
  }>();

  const title = {
    [WaysType.trail]: "Trails map",
    [WaysType.road]: "Roads map",
  }[way_type];

  const userArea = useGetUserCity(area_id);

  function renderPercentages() {
    if (userArea) {
      let percents = {
        [TimePeriods.all]: "",
        [TimePeriods.year]: "",
        [TimePeriods.monthly]: "",
      };
      if (way_type === WaysType.road) {
        percents = {
          [TimePeriods.all]: userArea.percent_covered_roads,
          [TimePeriods.year]: userArea.percent_covered_roads_year,
          [TimePeriods.monthly]: userArea.percent_covered_roads_month,
        };
      } else if (way_type === WaysType.trail) {
        percents = {
          [TimePeriods.all]: userArea.percent_covered_trails,
          [TimePeriods.year]: userArea.percent_covered_trails_year,
          [TimePeriods.monthly]: userArea.percent_covered_trails_month,
        };
      }
      return (
        <Content>
          <SmallHeading left={`You've explored`} />
          <div className={styles.container}>
            <Stat
              value={`${getPercent(percents[TimePeriods.all])}%`}
              label={"All time"}
              valueColor={"#d9814a"}
            />
            <Stat
              value={`${getPercent(percents[TimePeriods.year])}%`}
              label={"This year"}
              valueColor={"#d9814a"}
            />
            <Stat
              value={`${getPercent(percents[TimePeriods.monthly])}%`}
              label={"This month"}
              valueColor={"#d9814a"}
            />
          </div>
        </Content>
      );
    }
    return null;
  }
  return (
    <>
      <Helmet>
        <title>City Painter - Terms and conditions</title>
      </Helmet>
      <Header logoOnly title={title[way_type]} />
      <IonContent>
        <DisplayIf condition={Boolean(userArea)}>
          <Header title={title} />
          {/*this condition is purely to keep typescript happy*/}
          {userArea ? (
            <Map
              userCity={userArea}
              key={userArea?.user_city_id}
              period={TimePeriods.all}
              waysType={way_type}
            />
          ) : null}
          {renderPercentages()}
        </DisplayIf>
      </IonContent>
    </>
  );
}
