import React from "react";
import styles from "./CityList.module.css";
import City from "../City";
import { CityWithBBSimple } from "cp-server";
import DisplayIf from "../../../../components/display-if/DisplayIf";
import { useTranslation } from "react-i18next";
import shared from "../../../../styles/shared.module.css";

type PropsType = {
  cities: Array<CityWithBBSimple>;
};

export default function CityList(props: PropsType) {
  const cities = props.cities;
  const { t } = useTranslation();
  return (
    <div className={styles.cityList}>
      {cities.map((city) => (
        <City city={city} key={city.area_id} />
      ))}
      <DisplayIf condition={cities.length === 0}>
        <div className={shared.text}>{t("explore.noResults")}</div>
      </DisplayIf>
    </div>
  );
}
