import { AccountTypes, AreaType, UserCityType } from "cp-server";
import { sortAreas } from "../utils/formating/UserCityUtils";
import { isWeb } from "../../platform/PlatformManager";
import { User } from "framework";
const valuesForFreeAccount = {
  neighbourhoods: 6,
  userCities: 3,
  customAreas: 10,
  landmarks: 16,
};

function showPremiumOptions() {
  return true; // For testing TODO should be based on an env variable? Should be TRUE for production
}

function accountIsFree() {
  // return true; // - for testing
  return User.getAccountType() === AccountTypes.free;
}

function getNumberOfNeighbourhoods() {
  return accountIsFree() ? valuesForFreeAccount.neighbourhoods : undefined;
}

function getNumberOfLandmarks() {
  return accountIsFree() ? valuesForFreeAccount.landmarks : undefined;
}

function getNumberOfUserAreas() {
  if (isWeb()) {
    return accountIsFree() ? 6 : undefined; // temporary - slowly reduce this over time to come into line with the app
  }
  return accountIsFree() ? valuesForFreeAccount.userCities : undefined;
}

function getNumberOfCustomAreas() {
  return accountIsFree() ? valuesForFreeAccount.customAreas : undefined;
}

function processUserAreasList(
  userAreas: Array<UserCityType>,
  homeAreaId?: string
) {
  const limit = getNumberOfUserAreas();
  // User areas are initially created by comparing area and activity bounding boxes (because its fast!)
  // This means its possible a user city to have no actual activity polylines in it. resulting in 0% coverage
  // Filter these out

  const nonHomeAreas = userAreas.filter((ua) => ua.area_id !== homeAreaId);
  const homeArea = userAreas.filter((ua) => ua.area_id === homeAreaId);

  const userAreasWithActivities = nonHomeAreas.filter(
    (ua) => parseFloat(ua.percent_covered) > 0
  );
  /**
   * The purpose of this is to limit the standard cities available to free users while making sure all their custom areas are shown
   * We also want to make sure cities are in the correct order (sorted by percent covered)
   */
  const customAreas = userAreasWithActivities.filter(
    (c) => c.type === AreaType.custom
  );

  const otherAreas = sortAreas(
    userAreasWithActivities.filter((c) => c.type !== AreaType.custom)
  );
  const otherAreasFiltered = otherAreas.filter((area) =>
    [AreaType.national_park, AreaType.scenic_area].includes(area.type)
      ? !accountIsFree()
      : true
  );

  const otherAreasLimited = otherAreasFiltered.slice(0, limit);

  const availableAreas = sortAreas(
    customAreas.concat(otherAreasLimited).concat(homeArea)
  );

  const lockedAreas = userAreasWithActivities.filter(
    (area) => !availableAreas.includes(area)
  );
  return {
    availableAreas,
    lockedAreas,
  };
}

export default {
  accountIsFree,
  getNumberOfCustomAreas,
  getNumberOfNeighbourhoods,
  getNumberOfLandmarks,
  getNumberOfUserAreas,
  processUserAreasList,
  showPremiumOptions,
};
