import { FileSharer } from "@byteowls/capacitor-filesharer";
import Logo from "../assets/cp_logo.png";
import { getPercent } from "./utils/PercentageUtils";

function share({ fileName, b64Image }: { fileName; b64Image }) {
  FileSharer.share({
    filename: `${fileName}.png`,
    contentType: "image/png;",
    base64Data: b64Image.replace("data:image/png;base64,", ""), // If you want to save a file from a path:
  })
    .then(() => {
      console.log("share done");
    })
    .catch((error) => {
      console.error("File sharing failed", error.message);
    });
}

function sharePlaceImage({
  imgUrl,
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
}: {
  imgUrl: string;
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
}) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  const cpLogo = new Image();
  cpLogo.src = Logo;
  cpLogo.crossOrigin = "anonymous";

  const img = new Image();
  img.src = imgUrl;
  img.crossOrigin = "anonymous";
  const topHeight = 70;
  img.onload = function () {
    console.log("IMAGE:", img.height, img.width);
    canvas.width = img.width + 80;
    canvas.height = img.height + 84 + topHeight + 40;

    const textStart = img.height + 40 + topHeight;

    ctx.strokeStyle = "#FD7F00";
    ctx.fillStyle = "#2c353d";
    ctx.lineWidth = 10;
    ctx.beginPath();
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.roundRect(10, 10, canvas.width - 20, canvas.height - 20, [16]);
    ctx.stroke();

    ctx.drawImage(img, 40, topHeight + 20);
    ctx.drawImage(cpLogo, 40, 30, 175 * 2, 23 * 2);

    ctx.fillStyle = "white";
    ctx.font = "36px Montserrat";
    ctx.fillText(leftLabel, 40, textStart);

    ctx.fillStyle = "#d9814a";
    ctx.font = "bold 48px Montserrat";

    ctx.fillText(leftValue, 40, textStart + 52);

    ctx.fillStyle = "white";
    ctx.font = "36px Montserrat";
    ctx.fillText(rightLabel, img.width - 240, textStart);

    ctx.fillStyle = "#d9814a";
    ctx.font = "bold 48px Montserrat";
    ctx.fillText(rightValue, img.width - 240, textStart + 52);

    const base64 = canvas.toDataURL("image/png");
    share({
      fileName: `${leftLabel}.png`,
      b64Image: base64.replace("data:image/png;base64,", ""),
    });
  };
}

export default {
  share,
  sharePlaceImage,
};
